import { Observable } from 'rxjs';

export enum VariableType {
  number = 'Number',
  string = 'String',
  boolean = 'Boolean',
}

export interface IWorkbookVariable {
  id: string;
  name: string;
  value: VariableValue;
  type: VariableType;
  subtype?: string;
  excludeFromBookmarks?: boolean;
  created: string;
}

export interface IUpdateWorkbookVariable {
  name?: string;
  value?: VariableValue;
  type?: VariableType;
  subtype?: string;
  excludeFromBookmarks?: boolean;
}

export interface ICreateWorkbookVariable {
  name: string;
  value: VariableValue;
  type: VariableType;
  subtype?: string;
  excludeFromBookmarks?: boolean;
}

export interface WorkbookContextOptions {
  useStorage: boolean;
  isMockWorkbook: boolean;
}

export type VariableValue = boolean | string | number;

export interface IVariable {
  get id(): string;
  set id(id: string);
  get name(): string;
  set name(name: string);
  get value();
  set value(value: VariableValue);
  get rawValue(): VariableValue;
  get type();
  set type(type: VariableType);
  get subtype(): string;
  set subtype(subtype: string);
  get excludeFromBookmarks();
  set excludeFromBookmarks(excludeFromBookmarks: boolean);
  get created();
  set created(created: string);
  get valueChange(): Observable<VariableValue>;
  get rawValueChange(): Observable<VariableValue>;
  get onRemove(): Observable<void>;
  get rawVariable(): IWorkbookVariable;

  updateVariable(variable: IWorkbookVariable): void;
  triggerOnRemoveEvent(): void;
}

export interface VariableWatchValue {
  variableId: IVariable['id'];
  variableName: IVariable['name'];
  value: IVariable['value'];
}
