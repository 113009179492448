import { NgModule, inject, provideAppInitializer } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { ConfigService } from './shared/services/config.service';
import { NgxCaptchaModule } from 'ngx-captcha';
import { API_URL, BASE_URL } from '@gucci/constants';

const initConfig = (configService: ConfigService) => {
  return () => configService.sync();
};

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  // consider including in main bundle
  return import('lottie-web/build/player/lottie_svg');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LottieComponent,
    AppRoutingModule,
    NgxCaptchaModule,
  ],
  providers: [
    ConfigService,
    provideAppInitializer(() => {
      const initializerFn = initConfig(inject(ConfigService));
      return initializerFn();
    }),

    { provide: API_URL, useValue: BASE_URL },

    provideLottieOptions({
      player: playerFactory,
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
